
// Required dependencies
import 'jquery';
import {Modal} from 'bootstrap';

// Assets
import "./theme/theme.scss";

//Nette
import naja from 'naja';
import netteForms from 'nette-forms';

//init Nette Forms
window.Nette = netteForms;
netteForms.initOnLoad();

$(document).ready(function() {
    //hamburger menu toggle
    $('#small-screen-menu-href').click(function() {
        if ($('#small-menu').css('display') === 'none') {
            $('#small-menu').css('display','block');
        } else {
            $('#small-menu').css('display','none');
        }
        return false;
    });

    $('#small-screen-left-menu-href').click(function() {
        if ($('#left-panel-menu').css('display') === 'none') {
            $('#left-panel-menu').css('display','block');
        } else {
            $('#left-panel-menu').css('display','none');
        }
        return false;
    });

    //paginator
    $('.paginatorItemsPerPage').change(function() {
       $(this).closest('form').first().submit();
    });

    //products list
    $('.products-list-row').css('cursor', 'pointer').click(function() {
        const href = $(this).find('a').first().attr('href');
        window.location = href;
    });

    if ($('#productIDs').val()) {
        $.post("/category/product-details?lang=" + $('#productLang').val(), {
            productIDs: $('#productIDs').val()
        }, function (json) {
            const productIDs = JSON.parse($('#productIDs').val());
            $(productIDs).each(function(index, productID){
                const preg = /[^0-9A-Za-z]{1,}?/g;
                productID = productID.replace(preg, '_');
                const id ='product'+productID;
                const details = eval('json.'+id);
                $('.product'+productID).find('.productPriceIncVat').html(details.price);
                $('.product'+productID).find('.productSpecialPrice').html(details.specialPrice);
                $('.product'+productID).find('.productAvailability').html(details.availability);
                $('.product'+productID).find('.productAvailability1Week').html(details.availability1Week);
                $('.product'+productID).find('.productAvailability2Week').html(details.availability2Week);
                $('.product'+productID).find('.productAvailability4Week').html(details.availability4Week);
                $('.product'+productID).find('.productAvailability6Week').html(details.availability6Week);
                $('.product'+productID).find('.productAvailability8Week').html(details.availability8Week);
                $('.product'+productID).find('.productAvailabilityOver8Week').html(details.availabilityOver8Week);
            });
        },'json');
    }

    if ($('#searchProductIDs').val()) {
        $.post("/search-results/product-details?lang=" + $('#productLang').val(), {
            productIDs: $('#searchProductIDs').val()
        }, function (json) {
            const productIDs = JSON.parse($('#searchProductIDs').val());
            $(productIDs).each(function(index, productID){
                const preg = /[^0-9A-Za-z]{1,}?/g;
                productID = productID.replace(preg, '_');
                const id ='product'+productID;
                const details = eval('json.'+id);
                $('.product'+productID).find('.productCategory').html(details.category);
                $('.product'+productID).find('.productPriceIncVat').html(details.price);
                $('.product'+productID).find('.productSpecialPrice').html(details.specialPrice);
                $('.product'+productID).find('.productAvailability').html(details.availability);
                $('.product'+productID).find('.productAvailability1Week').html(details.availability1Week);
                $('.product'+productID).find('.productAvailability2Week').html(details.availability2Week);
                $('.product'+productID).find('.productAvailability4Week').html(details.availability4Week);
                $('.product'+productID).find('.productAvailability6Week').html(details.availability6Week);
                $('.product'+productID).find('.productAvailability8Week').html(details.availability8Week);
                $('.product'+productID).find('.productAvailabilityOver8Week').html(details.availabilityOver8Week);
            });
        },'json');
    }

    if ($('#actionProductIDs').val()) {
        $.post("/category/action-details?lang=" + $('#productLang').val(), {
            productIDs: $('#actionProductIDs').val()
        }, function (json) {
            const productIDs = JSON.parse($('#actionProductIDs').val());
            $(productIDs).each(function(index, productID){
                const preg = /[^0-9A-Za-z]{1,}?/g;
                productID = productID.replace(preg, '_');
                const id ='product'+productID;
                const details = eval('json.'+id);
                $('.product'+productID).find('.productPrice').html(details.price);
                $('.product'+productID).find('.productPriceIncVat').html(details.priceIncVat);
                $('.product'+productID).find('.productSpecialPrice').html(details.specialPrice);
                $('.product'+productID).find('.productSpecialPriceIncVat').html(details.specialPriceIncVat);
            });
        },'json');
    }

    if ($('#priceListProductIDs').val()) {
        $.post("/customer/price-list-details?lang=" + $('#productLang').val(), {
            productIDs: $('#priceListProductIDs').val()
        }, function (json) {
            const productIDs = JSON.parse($('#priceListProductIDs').val());
            $(productIDs).each(function(index, productID){
                const preg = /[^0-9A-Za-z]{1,}?/g;
                productID = productID.replace(preg, '_');
                const id ='product'+productID;
                const details = eval('json.'+id);
                $('.product'+productID).find('.productPrice').html(details.price);
                $('.product'+productID).find('.productPriceIncVat').html(details.priceIncVat);
                $('.product'+productID).find('.productSpecialPrice').html(details.specialPrice);
                $('.product'+productID).find('.productSpecialPriceIncVat').html(details.specialPriceIncVat);
            });
        },'json');
    }


    //search modals
    if (document.getElementById('rootCategoriesModal')) {

        const rcModalEl = document.getElementById('rootCategoriesModal');
        const scModalEl = document.getElementById('subCategoriesModal');

        $('#confirmRootCategories').click(function() {
            let selected = '';
            let selectedTitles = '';
            $('.main-category').each(function() {
                if (this.checked) {
                    selected += selected ? (','+$(this).attr('value')) : $(this).attr('value');
                    selectedTitles += selectedTitles ? (','+$(this).next().text()) : $(this).next().text();
                }
            })

            $('#searchRootCategoriesTitles').val(selectedTitles);
            $('#searchRootCategories').val(selected);
            $('.sub-category').each(function() { this.checked = false; });
            $('#searchSubCategoriesTitles').val('');
            $('#searchSubCategories').val('');

            const rcModal = Modal.getInstance(rcModalEl)
            rcModal.hide();
        });

        scModalEl.addEventListener('show.bs.modal', function(event) {
            const selectedRootCategories = $('#searchRootCategories').val();
            if (selectedRootCategories) {
                $('.search-main-category').hide();
                $.each(selectedRootCategories.split(','), function(index, node) {
                    $('#category-'+node).show();
                });
            }
        });

        $('#confirmSubCategories').click(function() {
            let selected = '';
            let selectedTitles = '';
            $('.sub-category').each(function() {
                if (this.checked) {
                    selected += selected ? (','+$(this).attr('value')) : $(this).attr('value');
                    selectedTitles += selectedTitles ? (','+$(this).next().text()) : $(this).next().text();
                }
            })

            $('#searchSubCategoriesTitles').val(selectedTitles);
            $('#searchSubCategories').val(selected);

            const scModal = Modal.getInstance(scModalEl)
            scModal.hide();
        });

        $('#emptyRootCategories').click(function() {
            $('#searchRootCategoriesTitles').val('');
            $('#searchRootCategories').val('');
            $('.main-category').each(function() { this.checked = false; });
            $('.search-main-category').show();
            const rcModal = Modal.getInstance(rcModalEl)
            rcModal.hide();
        });

        $('#emptySubCategories').click(function() {
            $('#searchSubCategoriesTitles').val('');
            $('#searchSubCategories').val('');
            $('.sub-category').each(function() { this.checked = false; });
            const scModal = Modal.getInstance(scModalEl)
            scModal.hide();
        });
    }


    //order step1
    if ($('select[name="deliveryType"]')) {
        const insertDateAllow = [];
        $('.insert-date-allow').each(function () {
            insertDateAllow.push($(this).val());
        })
        if ($.inArray($('select[name="deliveryType"]').val(), insertDateAllow) < 0) {
            $('input[name="deliveryDate"]').closest('.row').hide();
        } else {
            $('input[name="deliveryDate"]').closest('.row').show();
        }
        $('select[name="deliveryType"]').change(function () {
            if ($.inArray($(this).val(), insertDateAllow) < 0) {
                $('input[name="deliveryDate"]').closest('.row').hide();
            } else {
                $('input[name="deliveryDate"]').closest('.row').show();
            }
        })
        $('select[name="deliveryAddress"]').change(function (){
            if ($(this).val() === 'Jine') {
                $('#differentAddressWrapper').show();
            } else {
                $('#differentAddressWrapper').hide();
            }
        })
        if ($('select[name="deliveryAddress"]').val() === 'Jine') {
            $('#differentAddressWrapper').show();
        }
    }

    //sales person cart
    $('.salesEditPrice').click(function() {
       $(this).closest('tr').find('.editHide').hide();
       $(this).closest('tr').find('.editShow').css('display','inline');
       return false;
    });

    $('.editShow input').on('input', function() {
        this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    });

    //sales person customers
    $("#customersFilter").on("keyup", function() {
        var value = $(this).val().toLowerCase();
        $("#customerList li").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
        });
    });
})